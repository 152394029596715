<template>
  <dialogComp
    width="640px"
    :title="dialogType === 'add' ? '新增人员' : '编辑人员'"
    :isShow="isShow"
    @close="close"
    @sure="sure"
  >
    <el-form
      :model="formData"
      :rules="rules"
      ref="formData"
      label-width="100px"
    >
      <div class="flex">
        <el-form-item v-if="dialogType === 'add'" label="用户名:" prop="loginName">
          <el-select
            v-model="formData.loginName"
            filterable
            allow-create
            default-first-option
            placeholder="可选可输入用户名"
            size="small"
            @change="loginNameChange"
          >
          <el-option
              v-for="item in loginNameList"
              :key="item"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <!-- 编辑状态不自动获取  -->
        <inputFormComp
          v-else
          label="用户名:"
          prop="loginName"
          :hval="formData.loginName"
          @blur="loginNameBlur"
          placeholder="请输入用户名"
        />
        <inputFormComp
          label="姓名:"
          prop="realName"
          :hval="formData.realName"
          @blur="realNameBlur"
          placeholder="请输入姓名"
        />
      </div>
      <div class="flex">
        <el-form-item v-if="dialogType === 'add'" label="手机号:" prop="mobile">
          <el-select
            v-model="formData.mobile"
            filterable
            allow-create
            default-first-option
            placeholder="可选可输入手机号"
            size="small"
            @change="mobileChange"
          >
          <el-option
              v-for="item in mobileList"
              :key="item"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <!-- 编辑状态不自动获取  -->
        <inputFormComp
          v-else
          label="手机号:"
          prop="mobile"
          :hval="formData.mobile"
          @blur="mobileBlur"
          placeholder="请输入手机号"
        />
        <inputFormComp
          label="密码:"
          :prop="dialogType === 'add' && !formData.userId ? 'password' : ''"
          :hval="formData.password"
          @blur="passwordBlur"
          placeholder="请输入密码"
        />
      </div>
      <div class="flex">
        <selectFormComp
          label="角色:"
          prop="storeRole"
          :data="storeRoleList"
          :hval="formData.storeRole"
          @change="storeRoleChange"
          placeholder="请选择角色"
        />
        <selectFormComp
          label="状态:"
          prop="status"
          :data="statusList"
          :hval="formData.status"
          @change="statusChange"
          placeholder="请选择状态"
        />
      </div>
       <div class="flex">
         <authRoles
          label="权限角色:"
          prop="authorityRole"
          :roleType="2"
          :hval="formData.authorityRole"
          @change="authorityRoleChange"
        />
        <radioFormComp
          style="width: 52%"
          label="性别:"
          prop="sex"
          :radioList="radioList"
          :hval="formData.sex"
          @change="sexChange"
        />
       </div>
      <inputFormComp
        label="备注:"
        prop="remark"
        inputType="textarea"
        :hval="formData.remark"
        @blur="remarkBlur"
        placeholder="请输入备注"
      />
    </el-form>
  </dialogComp>
</template>
<script>
import authRoles from '@/views/components/authRoles'
import { supplierUserAdd, supplierUserEdit, userList } from '@/api'
export default {
  name: 'people-add',
  components: {
    authRoles
  },
  props: {
    isShow: {
      type: Boolean,
      default: false
    },
    dialogType: {
      type: String,
      default: 'add'
    },
    info: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    const checkMobile = (rule, val, cb) => {
      // var Reg = /^1[3456789]\d{9}$/
      var Reg = /^\d+$/
      if (Reg.test(val)) {
        return cb()
      }
      cb(new Error('必须输入数字'))
    }
    return {
      radioList: [
        { label: '男', value: 1 },
        { label: '女', value: 2 }
      ],
      storeRoleList: [
        { label: '商家管理员', value: 1 },
        { label: '商家售后', value: 2 },
        { label: '商家调度', value: 3 },
        { label: '商家财务', value: 4 }
      ],
      statusList: [
        { label: '正常', value: 1 },
        { label: '冻结', value: 2 }
      ],
      loginNameList: [],
      mobileList: [],
      formData: {
        loginName: '',
        realName: '',
        password: '',
        mobile: '',
        storeRole: null,
        authorityRole: '',
        status: null,
        sex: 1,
        remark: '',
        userId: null // 只有选择已有的数据才传
      },
      rules: {
        realName: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
        loginName: [
          { required: true, message: '请选择或输入用户名', trigger: 'change' }
        ],
        mobile: [
          { required: true, message: '请选择或输入手机号', trigger: 'change' },
          { validator: checkMobile, trigger: 'change' }
        ],
        password: [
          { required: true, message: '请输入登录密码', trigger: 'blur' }
        ],
        storeRole: [{ required: true, message: '请选择角色', trigger: 'change' }],
        status: [{ required: true, message: '请选择状态', trigger: 'change' }],
        authorityRole: [
          { required: true, message: '请输入权限角色', trigger: 'change' }
        ]
      }
    }
  },
  watch: {
    isShow (val) {
      val && this.getMerchantUserList()
      if (val && this.dialogType === 'edit') {
        const { loginName, realName, mobile, storeRole, status, authorityRole, sex, remark, userId } = this.info
        this.formData.loginName = loginName
        this.formData.realName = realName
        this.formData.mobile = mobile
        this.formData.storeRole = storeRole
        this.formData.status = status
        this.formData.sex = sex
        this.formData.remark = remark
        this.formData.userId = userId
        setTimeout(() => {
          this.formData.authorityRole = authorityRole
        }, 300)
      }
    }
  },
  methods: {
    // 获取已有的商家用户
    getMerchantUserList () {
      const params = {
        roleType: 2, // 角色类别，1：系统用户，2：商家用户
        curPage: 1,
        pageSize: 1000
      }
      userList(params).then(res => {
        const list = res.data.list
        this.merchantUserList = list
        this.loginNameList = list.map(item => item.loginName)
        this.mobileList = list.map(item => item.mobile)
      })
    },
    loginNameChange (val) {
      this.formData.loginName = val
      const item = this.merchantUserList.find(item => item.loginName === val)
      if (item) {
        const { realName, mobile, status, sex, id } = item
        this.formData.realName = realName
        this.formData.mobile = mobile
        this.formData.status = status
        this.formData.sex = sex
        this.formData.userId = id
      } else {
        // 判断手机号在不在已有数据库中
        const flag = this.merchantUserList.some(item => item.mobile === this.formData.mobile)
        if (flag) {
          this.formData.mobile = ''
          this.formData.realName = ''
          this.formData.mobile = ''
          this.formData.status = null
          this.formData.sex = 1
          this.formData.userId = null
        }
      }
    },
    realNameBlur (val) {
      this.formData.realName = val
    },
    loginNameBlur (val) {
      this.formData.loginName = val
    },
    mobileBlur (val) {
      this.formData.mobile = val
    },
    mobileChange (val) {
      this.formData.mobile = val
      const item = this.merchantUserList.find(item => item.mobile === val)
      if (item) {
        const { realName, loginName, status, sex, id } = item
        this.formData.realName = realName
        this.formData.loginName = loginName
        this.formData.status = status
        this.formData.sex = sex
        this.formData.userId = id
      } else {
        // 判断用户名在不在已有数据库中
        const flag = this.merchantUserList.some(item => item.loginName === this.formData.loginName)
        if (flag) {
          this.formData.loginName = ''
          this.formData.realName = ''
          this.formData.status = null
          this.formData.sex = 1
          this.formData.userId = null
        }
      }
    },
    passwordBlur (val) {
      this.formData.password = val
    },
    storeRoleChange (val) {
      this.formData.storeRole = val
    },
    authorityRoleChange (val) {
      this.formData.authorityRole = val
    },
    statusChange (val) {
      this.formData.status = val
    },
    sexChange (val) {
      this.formData.sex = val
    },
    remarkBlur (val) {
      this.formData.remark = val
    },
    close () {
      this.formData = {
        loginName: '',
        realName: '',
        password: '',
        mobile: '',
        storeRole: null,
        authorityRole: '',
        status: null,
        sex: 1,
        remark: '',
        userId: null // 只有选择已有的数据才传
      }
      this.$refs.formData.resetFields()
      this.$emit('close')
    },
    sure () {
      this.$refs.formData.validate(valid => {
        if (valid) {
          const params = {
            ...this.formData,
            storeId: this.$route.query.storeId
          }
          if (this.dialogType === 'add') {
            supplierUserAdd(params).then(_ => {
              this.$message.success('新增成功')
              this.close()
            })
          } else {
            supplierUserEdit({ ...params, id: this.info.id }).then(_ => {
              this.$message.success('修改成功')
              this.close()
            })
          }
        }
      })
    }
  }
}
</script>
