<template>
  <div class="main_box">
    <div class="bill_info main_box_con">
      <div class="bill_info_box">
        <el-form
          :model="formData"
          :rules="rules"
          ref="formData"
          label-width="150px"
        >
          <inputFormComp
            label="公司名称:"
            prop="companyName"
            :hval="formData.companyName"
            @blur="companyNameBlur"
            placeholder="请输入公司名称"
          />
          <inputFormComp
            label="纳税人识别号:"
            prop="taxpayerNum"
            :hval="formData.taxpayerNum"
            @blur="taxpayerNumBlur"
            placeholder="请输入纳税人识别号"
          />
          <inputFormComp
            label="地址/电话:"
            prop="info"
            :hval="formData.info"
            @blur="infoBlur"
            placeholder="请输入地址/电话"
          />
          <inputFormComp
            label="开户银行名称:"
            prop="bankName"
            :hval="formData.bankName"
            @blur="bankNameBlur"
            placeholder="请输入开户银行名称"
          />
          <inputFormComp
            label="开户行账号:"
            prop="bankNum"
            :hval="formData.bankNum"
            @blur="bankNumBlur"
            placeholder="请输入开户行账号"
          />
          <inputFormComp
            label="备注:"
            prop="remark"
            inputType="textarea"
            :hval="formData.remark"
            @blur="remarkBlur"
            placeholder="请输入备注"
          />
        </el-form>
        <div class="footer">
          <btnComp @click="close" btnType="">取 消</btnComp>
          <btnComp @click="sure">确定</btnComp>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { supplierBillInfo, supplierBillInfoAdd } from '@/api'
export default {
  data () {
    return {
      formData: {
        companyName: '',
        taxpayerNum: '',
        info: '',
        bankName: '',
        bankNum: '',
        remark: ''
      },
      rules: {}
    }
  },
  mounted () {
    this.getDetail()
  },
  methods: {
    getDetail () {
      supplierBillInfo({ storeId: this.$route.query.storeId }).then(res => {
        if (res.data) {
          const { companyName, taxpayerNum, info, bankName, bankNum, remark } = res.data
          this.formData = { companyName, taxpayerNum, info, bankName, bankNum, remark }
        }
      })
    },
    companyNameBlur (val) {
      this.formData.companyName = val
    },
    taxpayerNumBlur (val) {
      this.formData.taxpayerNum = val
    },
    infoBlur (val) {
      this.formData.info = val
    },
    bankNameBlur (val) {
      this.formData.bankName = val
    },
    bankNumBlur (val) {
      this.formData.bankNum = val
    },
    remarkBlur (val) {
      this.formData.remark = val
    },
    close () {
      this.getDetail()
    },
    sure () {
      this.$refs.formData.validate(valid => {
        if (valid) {
          const params = {
            ...this.formData,
            storeId: this.$route.query.storeId
          }
          supplierBillInfoAdd(params).then(_ => {
            this.$message.success('更新成功')
          })
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.bill_info {
  .bill_info_box {
    padding: 20px 50px 0 0;
    .footer {
      text-align: center;
    }
  }
}
</style>
