<template>
  <div class="main_box">
    <div class="main_box_con">
      <btnComp @click="add('add')" v-permission="'地址新增'">新增地址</btnComp>
      <tableComp
          :data="tableData"
          :col-configs="theadName"
          :total="total"
          :currentPage="curPage"
          :pageSize="pageSize"
          @handleSizeChange="handleSizeChange"
          @handleCurrentChange="handleCurrentChange"
        >
        <el-table-column slot="defaultType" label="默认使用" >
           <template v-slot="scope">
             {{scope.row.defaultType?'∨':''}}
           </template>
        </el-table-column>
        <el-table-column slot="operate" label="操作" fixed="right">
            <template v-slot="scope">
              <btnComp btnType="text" @click="doCopy(scope.row)">一键复制</btnComp>
              <btnComp btnType="text" @click="add('edit',scope.row)" v-permission="'地址编辑'">编辑</btnComp>
              <btnComp style="color:red" btnType="text" @click="del(scope.row)" v-permission="'地址删除'">删除</btnComp>
            </template>
        </el-table-column>
      </tableComp>
    </div>
    <add :isShow="addShow" @close="close" :dialogType="dialogType" :info="info" />
    <dialogComp
        title="提示"
        :isShow="hintShow"
        @close="close"
        @sure="hintSure"
      >
      <p>确定删除吗？</p>
    </dialogComp>
  </div>
</template>
<script>
import add from './add'
import { supplierAddressList, supplierAddressDel } from '@/api'
export default {
  name: 'address-info',
  components: {
    add
  },
  data () {
    return {
      hintShow: false,
      addShow: false,
      dialogType: 'add',
      info: {},
      curPage: 1,
      pageSize: 10,
      total: 0,
      tableData: [],
      theadName: [
        { prop: 'remark', label: '备注' },
        // { prop: 'storeName', label: '默认使用' },
        { slot: 'defaultType' },
        { prop: 'addressTypeName', label: '地址类型' },
        { prop: 'provinceCityRegion', label: '省市区' },
        { prop: 'detailAddress', label: '详细地址' },
        { prop: 'recipient', label: '收件人' },
        { prop: 'recipientMobile', label: '电话' },
        { slot: 'operate' }
      ]
    }
  },
  mounted () {
    this.getList()
  },
  methods: {
    getList () {
      const params = {
        storeId: this.$route.query.storeId,
        curPage: this.curPage,
        pageSize: this.pageSize
      }
      supplierAddressList(params).then(res => {
        const { list, totalCount } = res.data
        this.tableData = list
        this.total = totalCount
      })
    },
    // 一键复制
    doCopy (info) {
      const { provinceCityRegion, detailAddress, recipient, recipientMobile } = info
      // 拼接信息
      const msg1 = `收件地址：${provinceCityRegion}${detailAddress}`
      const msg2 = `收件人：${recipient}`
      const msg3 = `电话：${recipientMobile}`
      const message = [msg1, msg2, msg3].join('\n')
      this.$copyText(message).then(e => {
        this.$message.success('复制成功')
      }, e => {
        this.$message.success('复制失败')
      })
    },
    add (type, info) {
      this.addShow = true
      this.dialogType = type
      if (type === 'edit') {
        this.info = info
      }
    },
    // 删除操作
    del (row) {
      this.id = row.id
      this.hintShow = true
    },
    hintSure () {
      supplierAddressDel({ id: this.id }).then(_ => {
        this.$message.success('删除成功')
        this.close()
      })
    },
    close () {
      this.hintShow = false
      this.addShow = false
      this.getList()
    },
    handleSizeChange (val) {
      this.pageSize = val
      this.getList()
    },
    handleCurrentChange (val) {
      this.curPage = val
      this.getList()
    }
  }
}
</script>
