<template>
  <div class="main_box">
    <div class="senior_manage main_box_con">
      <div class="box">
        <div class="title">客户自管模式：</div>
          <el-radio-group v-model="mgmtMode" @change="mgmtModeChange">
            <el-radio :label="1">商家委托平台托管</el-radio>
            <el-radio :label="2">商家全部品类自管</el-radio>
            <el-radio :label="3">商家部分品类自管</el-radio>
          </el-radio-group>
      </div>
    </div>
  </div>
</template>
<script>
import { supplierManageEdit, supplierManageDetail } from '@/api'
export default {
  data () {
    return {
      mgmtMode: null
    }
  },
  mounted () {
    this.getDetail()
  },
  methods: {
    getDetail () {
      supplierManageDetail({ storeId: this.$route.query.storeId }).then(res => {
        this.mgmtMode = res.data.mgmtMode
      })
    },
    mgmtModeChange () {
      const params = {
        id: this.$route.query.id,
        mgmtMode: this.mgmtMode
      }
      supplierManageEdit(params).then(_ => {
        this.$message.success('修改成功')
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.senior_manage {
  padding: 50px;
  .box {
    display: flex;
    align-items: center;
    .title {
      font-weight: 700;
      font-size: 18px;
    }
    ::v-deep .el-radio__label {
      font-size: 16px;
    }
  }
}
</style>
