<template>
  <div class="main_box">
    <div class="main_box_con">
      <btnComp @click="add('add')" v-permission="'自理设备类目新增'">自理设备类目新增</btnComp>
      <tableComp
        :data="tableData"
        :col-configs="theadName"
        :total="total"
        :currentPage="curPage"
        :pageSize="pageSize"
        @handleSizeChange="handleSizeChange"
        @handleCurrentChange="handleCurrentChange"
      >
        <el-table-column slot="operate" label="操作" fixed="right">
          <template v-slot="scope">
             <btnComp btnType="text" @click="add('edit',scope.row)" v-permission="'自理设备类目编辑'"
              >编辑</btnComp
            >
            <btnComp style="color:red" btnType="text" @click="del(scope.row)" v-permission="'自理设备类目删除'"
              >删除</btnComp
            >
          </template>
        </el-table-column>
      </tableComp>
    </div>
    <add :isShow="addShow" @close="close" :dialogType="dialogType" :info="info" />
    <dialogComp title="提示" :isShow="hintShow" @close="close" @sure="hintSure">
      <p>确定删除吗？</p>
    </dialogComp>
  </div>
</template>
<script>
import add from './add'
import { supplierCategoryList, supplierCategoryDel } from '@/api'
export default {
  name: 'senior-manage',
  components: {
    add
  },
  data () {
    return {
      hintShow: false,
      addShow: false,
      dialogType: 'add',
      info: {},
      curPage: 1,
      pageSize: 10,
      total: 0,
      tableData: [
        {
          remark: '2222'
        }
      ],
      theadName: [
        { prop: 'createdTime', label: '更新日期' },
        { prop: 'categoryName', label: '自己处理的设备类目' },
        { slot: 'operate' }
      ]
    }
  },
  mounted () {
    this.getList()
  },
  methods: {
    getList () {
      const params = {
        storeId: this.$route.query.storeId,
        curPage: this.curPage,
        pageSize: this.pageSize
      }
      supplierCategoryList(params).then(res => {
        const { list, totalCount } = res.data
        this.tableData = list
        this.total = totalCount
      })
    },
    add (type, info) {
      this.addShow = true
      this.dialogType = type
      if (type === 'edit') {
        this.info = info
      }
    },
    // 删除操作
    del (row) {
      this.id = row.id
      this.hintShow = true
    },
    hintSure () {
      supplierCategoryDel({ id: this.id }).then(_ => {
        this.$message.success('删除成功')
        this.close()
      })
    },
    close () {
      this.hintShow = false
      this.addShow = false
      this.getList()
    },
    handleSizeChange (val) {
      this.pageSize = val
      this.getList()
    },
    handleCurrentChange (val) {
      this.curPage = val
      this.getList()
    }
  }
}
</script>
