<template>
  <div class="layout_common">
    <tabsComp :tabsList="tabsList" @change="tabsChange"/>
    <addressInfo v-if="active === '1'" />
    <peopleInfo v-if="active === '2'" />
    <afterServe v-if="active === '3'" />
    <billInfo v-if="active === '4'" />
    <seniorManage v-if="active === '5'" />
  </div>
</template>
<script>
import addressInfo from './address-info'
import peopleInfo from './people-info'
import afterServe from './after-serve'
import billInfo from './bill-info'
import seniorManage from './senior-manage'
export default {
  components: { addressInfo, peopleInfo, afterServe, billInfo, seniorManage },
  data () {
    return {
      active: '1',
      tabsList: [
        {
          name: '1',
          label: '地址信息'
        },
        {
          name: '2',
          label: '人员信息'
        },
        {
          name: '3',
          label: '售后服务'
        },
        {
          name: '4',
          label: '发票信息'
        },
        {
          name: '5',
          label: '高级管理'
        }
      ]
    }
  },
  methods: {
    tabsChange (val) {
      this.active = val
    }
  }
}
</script>
