<template>
  <dialogComp
    :title="dialogType==='add'?'新增自理设备类目':'编辑自理设备类目'"
    :isShow="isShow"
    @close="close"
    @sure="sure"
  >
    <el-form
      :model="formData"
      :rules="rules"
      ref="formData"
      label-width="100px"
    >
      <el-form-item label="类目:" prop="categoryId">
        <goodCategory :hval="formData.categoryId" @change="categoryIdChange" />
      </el-form-item>
    </el-form>
  </dialogComp>
</template>
<script>
import goodCategory from '@/views/components/goodCategory'
import { supplierCategoryAdd, supplierCategoryEdit } from '@/api'
export default {
  name: 'senior-manage',
  components: {
    goodCategory
  },
  props: {
    isShow: {
      type: Boolean,
      default: false
    },
    dialogType: {
      type: String,
      default: 'add'
    },
    info: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      formData: {
        categoryId: null,
        categoryName: ''
      },
      rules: {
        categoryId: [
          { required: true, message: '请选择类目', trigger: 'change' }
        ]
      }
    }
  },
  watch: {
    isShow (val) {
      if (val && this.dialogType === 'edit') {
        this.formData.categoryId = +this.info.categoryId
      }
    }
  },
  methods: {
    categoryIdChange (val) {
      this.formData.categoryId = val ? val.resultId : ''
      this.formData.categoryName = val ? val.resultName : ''
    },
    close () {
      this.formData = {
        categoryId: null,
        categoryName: ''
      }
      this.$refs.formData.resetFields()
      this.$emit('close')
    },
    sure () {
      this.$refs.formData.validate(valid => {
        if (valid) {
          const params = {
            ...this.formData,
            storeId: this.$route.query.storeId
          }
          if (this.dialogType === 'add') {
            supplierCategoryAdd(params).then(_ => {
              this.$message.success('新增成功')
              this.close()
            })
          } else {
            supplierCategoryEdit({ id: this.info.id, ...params }).then(_ => {
              this.$message.success('修改成功')
              this.close()
            })
          }
        }
      })
    }
  }
}
</script>
