<template>
  <dialogComp
    :title="dialogType === 'add' ? '新增地址' : '编辑地址'"
    :isShow="isShow"
    @close="close"
    @sure="sure"
  >
    <el-form
      :model="formData"
      :rules="rules"
      ref="formData"
      label-width="100px"
    >
      <div class="flex">
        <selectFormComp
          label="地址类型:"
          prop="addressType"
          :data="addressTypeList"
          :hval="formData.addressType"
          placeholder="请选择地址类型"
          @change="addressTypeChange"
        />
        <el-checkbox
          style="margin:10px 0 0 10px"
          :true-label="1"
          :false-label="0"
          v-model="formData.defaultType"
          >默认使用</el-checkbox
        >
      </div>
      <el-form-item label="省市区:" prop="area">
        <areaData :hval="formData.area" @change="areaChange" />
      </el-form-item>
      <inputFormComp
        label="详细地址:"
        prop="detailAddress"
        :hval="formData.detailAddress"
        @blur="detailAddressBlur"
        placeholder="请输入备注"
      />
      <inputFormComp
        label="联系人:"
        prop="recipient"
        :hval="formData.recipient"
        @blur="recipientBlur"
        placeholder="请输入联系人"
      />
      <inputFormComp
        label="联系方式:"
        prop="recipientMobile"
        :hval="formData.recipientMobile"
        @blur="recipientMobileBlur"
        placeholder="请输入联系方式"
      />
      <inputFormComp
        label="备注:"
        prop="remark"
        inputType="textarea"
        :hval="formData.remark"
        @blur="remarkBlur"
        placeholder="请输入备注"
      />
    </el-form>
  </dialogComp>
</template>
<script>
import areaData from '@/views/components/areaDataNew'
import { supplierAddressAdd, supplierAddressEdit } from '@/api'
import { handleArea } from '@/utils/common'
export default {
  name: 'address-add',
  components: {
    areaData
  },
  props: {
    isShow: {
      type: Boolean,
      default: false
    },
    dialogType: {
      type: String,
      default: 'add'
    },
    info: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    const checkMobile = (rule, val, cb) => {
      var Reg = /^1[3456789]\d{9}$/
      if (Reg.test(val)) {
        return cb()
      }
      cb(new Error('手机号码格式不正确'))
    }
    return {
      addressTypeList: [
        { label: '到期回收', value: 0 },
        { label: '更换寄回', value: 1 }
      ],
      formData: {
        addressType: null,
        defaultType: 1,
        province: '',
        city: '',
        region: '',
        area: [],
        detailAddress: '',
        recipient: '',
        recipientMobile: '',
        remark: ''
      },
      rules: {
        addressType: [
          { required: true, message: '请选择地址类型', trigger: 'change' }
        ],
        area: [
          { required: true, message: '请选择省市区', trigger: 'change' }
        ],
        detailAddress: [
          { required: true, message: '请输入详细地址', trigger: 'blur' }
        ],
        recipient: [
          { required: true, message: '请输入联系人', trigger: 'blur' }
        ],
        recipientMobile: [
          { required: true, message: '请输入联系方式', trigger: 'blur' },
          { validator: checkMobile, trigger: 'blur' }
        ]
      }
    }
  },
  watch: {
    isShow (val) {
      if (val && this.dialogType === 'edit') {
        const { addressType, defaultType, detailAddress, provinceCityRegion, recipient, recipientMobile, remark, province, city, region } = this.info
        this.formData.addressType = addressType
        this.formData.defaultType = defaultType
        this.formData.detailAddress = detailAddress
        this.formData.recipient = recipient
        this.formData.recipientMobile = recipientMobile
        this.formData.remark = remark
        if (!provinceCityRegion) return
        this.formData.area = handleArea(province, city, region)
        const [a, b, c] = this.formData.area
        this.formData.province = a || ''
        this.formData.city = b || ''
        this.formData.region = c || ''
      }
    }
  },
  methods: {
    addressTypeChange (val) {
      this.formData.addressType = val
    },
    areaChange (val) {
      this.formData.area = val
      const [a, b, c] = val
      this.formData.province = a || ''
      this.formData.city = b || ''
      this.formData.region = c || ''
    },
    detailAddressBlur (val) {
      this.formData.detailAddress = val
    },
    recipientBlur (val) {
      this.formData.recipient = val
    },
    recipientMobileBlur (val) {
      this.formData.recipientMobile = val
    },
    remarkBlur (val) {
      this.formData.remark = val
    },
    close () {
      this.formData = {
        addressType: null,
        defaultType: 0,
        province: '',
        city: '',
        region: '',
        area: [],
        detailAddress: '',
        recipient: '',
        recipientMobile: '',
        remark: ''
      }
      this.$refs.formData.resetFields()
      this.$emit('close')
    },
    sure () {
      this.$refs.formData.validate(valid => {
        if (valid) {
          const params = {
            ...this.formData,
            storeId: this.$route.query.storeId
          }
          if (this.dialogType === 'add') {
            supplierAddressAdd(params).then(_ => {
              this.$message.success('新增成功')
              this.close()
            })
          } else {
            supplierAddressEdit({ id: this.info.id, ...params }).then(_ => {
              this.$message.success('修改成功')
              this.close()
            })
          }
        }
      })
    }
  }
}
</script>
