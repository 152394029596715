<template>
  <div class="main_box">
    <div class="main_box_con">
      <btnComp @click="add('add')" v-permission="'人员新增'">新增人员</btnComp>
      <tableComp
          :data="tableData"
          :col-configs="theadName"
          :total="total"
          :currentPage="curPage"
          :pageSize="pageSize"
          @handleSizeChange="handleSizeChange"
          @handleCurrentChange="handleCurrentChange"
        >
        <el-table-column slot="operate" label="授权公司">
          <template v-slot="scope">
            <div style="color:#385BFF;cursor:pointer;line-height:50px" @click="getCompanyInfo('detail',scope.row)">{{scope.row.authorizedCompany}}</div>
          </template>
        </el-table-column>
        <el-table-column slot="operate" label="操作" fixed="right" width="150">
            <template v-slot="scope">
              <btnComp btnType="text" @click="add('edit',scope.row)" v-permission="'人员编辑'">编辑</btnComp>
              <btnComp style="color:red" btnType="text" @click="del(scope.row)" v-permission="'人员删除'">删除</btnComp>
              <btnComp btnType="text" @click="getCompanyInfo('set',scope.row)" v-permission="'人员登录设置'">登录设置</btnComp>
            </template>
        </el-table-column>
      </tableComp>
    </div>
    <add :isShow="addShow" @close="close" :dialogType="dialogType" :info="info" />
    <authorizeCompany :isShow="companyShow" @close="close" :info="info" :compDialogType="compDialogType" />
    <dialogComp
        title="提示"
        :isShow="hintShow"
        @close="close"
        @sure="hintSure"
      >
      <p>确定删除吗?</p>
    </dialogComp>
  </div>
</template>
<script>
import add from './add'
import authorizeCompany from './authorizeCompany.vue'
import { supplierUserList, supplierUserDel } from '@/api'
export default {
  name: 'people-info',
  components: {
    add,
    authorizeCompany
  },
  data () {
    return {
      hintShow: false,
      addShow: false,
      companyShow: false,
      dialogType: 'add',
      compDialogType: '',
      info: {},
      curPage: 1,
      pageSize: 10,
      total: 0,
      tableData: [],
      theadName: [
        { prop: 'updatedTime', label: '最新日期' },
        { prop: 'loginName', label: '用户名' },
        { prop: 'realName', label: '姓名' },
        { prop: 'authorityRoleName', label: '用户角色' },
        { prop: 'statusName', label: '用户状态' },
        { prop: 'workStatusName', label: '工作状态' },
        { prop: 'mobile', label: '手机号' },
        { prop: 'remark', label: '备注' },
        // { prop: 'authorizedCompany', label: '授权公司' },
        { slot: 'authorizedCompany' },
        { slot: 'operate' }
      ]
    }
  },
  mounted () {
    this.getList()
  },
  methods: {
    getList () {
      const params = {
        storeId: this.$route.query.storeId,
        curPage: this.curPage,
        pageSize: this.pageSize
      }
      supplierUserList(params).then(res => {
        const { list, totalCount } = res.data
        this.tableData = list
        this.total = totalCount
      })
    },
    add (type, info) {
      this.addShow = true
      this.dialogType = type
      if (type === 'edit') {
        this.info = info
      }
    },
    // 删除操作
    del (row) {
      this.id = row.id
      this.hintShow = true
    },
    hintSure () {
      supplierUserDel({ id: this.id }).then(_ => {
        this.$message.success('删除成功')
        this.close()
      })
    },
    // 获取授权公司
    getCompanyInfo (type, info) {
      this.info = info
      this.compDialogType = type
      this.companyShow = true
    },
    close () {
      this.hintShow = false
      this.addShow = false
      this.companyShow = false
      this.getList()
    },
    handleSizeChange (val) {
      this.pageSize = val
      this.getList()
    },
    handleCurrentChange (val) {
      this.curPage = val
      this.getList()
    }
  }
}
</script>
