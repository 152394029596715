<template>
  <dialogComp
    width="740px"
    :title="`${info.realName}-所在的公司`"
    :isShow="isShow"
    :isShowFooter="compDialogType === 'set'"
    @close="close"
    @sure="sure"
  >
    <tableComp :data="tableData" :col-configs="theadName">
      <el-table-column slot="defaultStore" label="缺省" width="100">
        <template v-slot="scope">
          <el-radio
            v-model="scope.row.defaultStore"
            :label="true"
            @change="defaultStoreChange(scope.row)"
            >{{ null }}</el-radio
          >
        </template>
      </el-table-column>
    </tableComp>
  </dialogComp>
</template>
<script>
import { supplierUserList, updateDefaultStore } from '@/api'
export default {
  name: 'authorizeCompany',
  props: {
    isShow: {
      type: Boolean,
      default: false
    },
    info: {
      type: Object,
      default: () => {}
    },
    compDialogType: {
      type: String,
      default: 'detail'
    }
  },
  data () {
    return {
      tableData: [],
      theadName: [
        { prop: 'storeRoleName', label: '角色' },
        // { prop: 'defaultStore', label: '缺省' },
        { slot: 'defaultStore' },
        { prop: 'storeName', label: '登录公司' }
      ]
    }
  },
  watch: {
    isShow (val) {
      val && this.getList()
    }
  },
  methods: {
    getList () {
      const params = {
        userId: this.info.userId
      }
      supplierUserList(params).then(res => {
        const { list, totalCount } = res.data
        this.tableData = list
        this.total = totalCount
        const item = this.tableData.find(item => item.defaultStore === true)
        this.userId = item.userId
        this.storeId = item.storeId
      })
    },
    defaultStoreChange ({ id, userId, storeId }) {
      this.userId = userId
      this.storeId = storeId
      this.tableData.forEach(item => {
        if (item.id !== id) {
          item.defaultStore = false
        }
      })
    },
    close () {
      this.$emit('close')
    },
    sure () {
      const params = {
        storeId: this.storeId,
        userId: this.userId
      }
      updateDefaultStore(params).then(_ => {
        this.$message.success('修改成功')
        this.close()
      })
    }
  }
}
</script>
